import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ErrorPage } from './pages/ErrorPage';
import { ReferenceDetail } from './pages/ReferenceDetail';
import { Overview } from './pages/Overview';
import { ReferenceObjectService } from './services/ReferenceObjectService';

const referenceObjectService = new ReferenceObjectService(process.env.REACT_APP_BACKEND!, process.env.REACT_APP_CDN_URL_PATH!);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const referenceObjectLoader = async (context:any) => {
  const referenceObject = await referenceObjectService.getById(context.params.referenceId);
  return referenceObject;
}
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('iframe');
const isIframe = myParam === "true";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App isIframe={isIframe}/>,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Overview service={referenceObjectService} isIframe={isIframe}/> },
      {
        path: "reference/:referenceId",
        element: <ReferenceDetail />,
        loader: referenceObjectLoader
      },
    ]
  },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
