import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Input,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarTitle
} from "@fluentui/react-components";
import { Copy20Regular, DismissRegular, Share16Regular } from "@fluentui/react-icons";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "../AppInsights";
import { ReferenceObject } from "../models/ReferenceObject";
export interface IShareDialogProps {
    referenceObject: ReferenceObject;
}

const ShareDialogComponent: React.FunctionComponent<IShareDialogProps> = (props: IShareDialogProps) => {
    const [copied,setCopied] = React.useState(false);
    const copyUrl = React.useMemo(() => {
        return `${window.location.protocol}//${window.location.host}/reference/${props.referenceObject.id}`;
    }, [props.referenceObject]);
    const copyClick = React.useCallback(() => {
        navigator.clipboard.writeText(copyUrl).then(() => setCopied(true));
    }, [copyUrl]);
    return (
        <Dialog modalType="alert" onOpenChange={(e)=>setCopied(false)}>
            <DialogTrigger disableButtonEnhancement>
                <Button icon={<Share16Regular />}>Teilen</Button>
            </DialogTrigger>
            <DialogSurface aria-describedby={undefined}>
                <DialogBody>
                    <DialogTitle>Referenz teilen</DialogTitle>
                    <DialogContent>
                        <p>
                            Der Link zu der Referenz kann kopiert werden.
                        </p>
                        {copied ?
                        <MessageBar intent="success">
                            <MessageBarBody>
                                <MessageBarTitle>Erfolg</MessageBarTitle>
                                Der Link wurde kopiert.
                            </MessageBarBody>
                            <MessageBarActions
                                containerAction={
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button
                                            aria-label="dismiss"
                                            appearance="transparent"
                                            icon={<DismissRegular />}
                                        />
                                    </DialogTrigger>
                                }
                            ></MessageBarActions>
                        </MessageBar> : null }
                        <div style={{ display: "flex" }}><Input readOnly={true} value={copyUrl} style={{ width: "100%" }}></Input><Button icon={<Copy20Regular />} onClick={copyClick}></Button></div>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Schliessen</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export const ShareDialog = withAITracking(reactPlugin, ShareDialogComponent);