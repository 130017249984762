import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { AdvancedMarker, AdvancedMarkerRef, InfoWindow, Pin, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import React from "react";
import { Link } from "react-router-dom";
import { reactPlugin } from "../AppInsights";
import { ReferenceObject } from "../models/ReferenceObject";

export interface IHeaderMapMarkerProps {
    referenceObject: ReferenceObject;
    cbMarkerRef: (mref: AdvancedMarkerRef | null) => void;
}

const HeaderMapMarkerComponent: React.FunctionComponent<IHeaderMapMarkerProps> = (props: IHeaderMapMarkerProps) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    
    const [infoWindowShown, setInfoWindowShown] = React.useState(false);
    const handleClose = React.useCallback(() => setInfoWindowShown(false), []);
    const handleMarkerClick = React.useCallback(
        () => setInfoWindowShown(isShown => !isShown),
        []
    );
    const assignMarkerRef = (mref: AdvancedMarkerRef | null) => {
        if (!marker) {
            props.cbMarkerRef(mref);
        }
        markerRef(mref)
    }
    return <><AdvancedMarker position={{ lat: props.referenceObject.latitude, lng: props.referenceObject.longitude }} ref={assignMarkerRef} onClick={handleMarkerClick} >
        <Pin background={'#009de0'} glyphColor={'#000'} borderColor={'#000'}></Pin>
    </AdvancedMarker>
        {infoWindowShown && (
            <InfoWindow anchor={marker} onClose={handleClose} headerContent={<Link to={`reference/${props.referenceObject.id}`}><strong>{props.referenceObject.objectName}</strong></Link>}>
                <p>{props.referenceObject.addressName}<br />
                    {props.referenceObject.postalCode} {props.referenceObject.city}
                </p>
            </InfoWindow>
        )}
    </>
}

export const HeaderMapMarker = withAITracking(reactPlugin, HeaderMapMarkerComponent);