import { Body1, Card, CardHeader, Subtitle1, Subtitle2 } from "@fluentui/react-components";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import { ObjectRelation } from "../models/ObjectRelation";

export interface IRoleObjectCardProps {
    relation: ObjectRelation;
}

const RoleObjectCardComponent: React.FunctionComponent<IRoleObjectCardProps> = (props: IRoleObjectCardProps) => {

    return <Card style={{width:"295px"}} size="large">
        <CardHeader header={<Subtitle1>{props.relation.roleName}</Subtitle1>}></CardHeader>
        <div>
            <div><Subtitle2>{props.relation.companyName}</Subtitle2></div>
            <div><Body1>{props.relation.address}</Body1></div>
            <div><Body1>{props.relation.postalCode} {props.relation.city}</Body1></div>
        </div>
    </Card>
}

export const RoleObjectCard = withAITracking(reactPlugin, RoleObjectCardComponent);