import { Button, Card, CardFooter, CardHeader, CardPreview, makeStyles, Text } from "@fluentui/react-components";
import { Open16Filled } from "@fluentui/react-icons";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { useNavigate } from "react-router-dom";
import { reactPlugin } from "../AppInsights";
import { ReferenceObject } from "../models/ReferenceObject";
import { ShareDialog } from "./ShareDialog";


export interface IReferenceObjectCardProps {
    item: ReferenceObject;
    isIframe: boolean;
}

const useStyles = makeStyles({
    card: {
        width: "320px",
        maxWidth: "100%",
        height: "fit-content",
    },
});


const ReferenceObjectCardComponent: React.FunctionComponent<IReferenceObjectCardProps> = (props: IReferenceObjectCardProps) => {
    const styles = useStyles();
    const navigate = useNavigate();
    const openLink = React.useCallback((ev: any) => {
        if (props.isIframe) {
            window.open(`${window.location.protocol}//${window.location.host}/reference/${props.item.id}`, "_blank");
        } else {
            navigate(`reference/${props.item.id}`)
        };
    }, [navigate, props.item, props.isIframe]);
    return <Card key={props.item.id} className={styles.card} size="large">
        <CardPreview>
            <img
                src={props.item.overviewPictureUrl}
                alt={props.item.description} />
        </CardPreview>
        <CardHeader
            header={<Text weight="semibold" truncate={true} wrap={false} style={{ maxWidth: "300px" }}>{props.item.objectName}</Text>}
            description={<div>
                <div><Text>{props.item.addressName}</Text></div>
                <div><Text>{props.item.postalCode}</Text> <Text>{props.item.city}</Text></div>
            </div>} />

        <CardFooter>
            <Button appearance="primary" icon={<Open16Filled />} onClick={openLink}>Öffnen</Button>
            {props.isIframe ? null : <ShareDialog referenceObject={props.item} />}
        </CardFooter>
    </Card>;
}

export const ReferencObjectCard = withAITracking(reactPlugin, ReferenceObjectCardComponent);