import { FluentProvider, Theme, webLightTheme } from '@fluentui/react-components';
import { AppInsightsContext, AppInsightsErrorBoundary, withAITracking } from '@microsoft/applicationinsights-react-js';
import './App.css';
import { reactPlugin } from './AppInsights';
import { ScreenLayout } from './components/ScreenLayout';
import { APIProvider } from '@vis.gl/react-google-maps';

export interface IApplicationProperties {
  isIframe: boolean;
}

//#193253
export const customLightTheme: Theme = {
  ...webLightTheme,
  colorBrandBackground: '#009de0', // overriden token
};

const AppComponent: React.FunctionComponent<IApplicationProperties> = (props: IApplicationProperties) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
        <FluentProvider theme={customLightTheme}>
          <APIProvider apiKey={process.env.REACT_APP_MAPKEY!}>
            {props.isIframe ? <ScreenLayout isIframe={props.isIframe}></ScreenLayout> : <>
              <header className="headerLTW clearfix">
                <div className="headerLine">
                  <div className="logo-wrap">
                    <a href="https://wir-die-gebaeudetechniker.ch" target="_blank" rel="noreferrer"><img src="/wdg_logo_de.svg" id="gebtech" height="200" width="95" alt="Wir die Gebäudetechniker"></img></a>
                    <a href="https://www.lufttechnik.ch/" rel="home"><img width="311" height="64" src="/logo_ltw_b_rlkk.svg" alt="Lufttechnik AG" decoding="async"></img></a></div>
                  <div className="mainMenu" style={{alignSelf:"center"}}>Referenzen
                  </div>
                </div>
              </header>
              <ScreenLayout isIframe={props.isIframe} />
            </>}
          </APIProvider>
        </FluentProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

export const App = withAITracking(reactPlugin, AppComponent);
