import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import { ReferenceObject } from "../models/ReferenceObject";
import { HeaderMapMarker } from "./HeaderMapMarker";
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import type { Marker } from '@googlemaps/markerclusterer';
import { useMap } from "@vis.gl/react-google-maps";
import React from "react";

export interface IHeaderMapMarkerCollectionProps {
    referenceObjects: ReferenceObject[];
}

const HeaderMapMarkerCollectionComponent: React.FunctionComponent<IHeaderMapMarkerCollectionProps> = (props: IHeaderMapMarkerCollectionProps) => {
    const map = useMap();
    const [markers, setMarkers] = React.useState<{ [key: string]: Marker }>({});
    const clusterer = React.useRef<MarkerClusterer | null>(null);
    React.useEffect(() => {
        if (!map) return;
        if (!clusterer.current) {
            clusterer.current = new MarkerClusterer({ map });
        }
    }, [map]);
    
    // Update markers, if the markers array has changed
    React.useEffect(() => {
        clusterer.current?.clearMarkers();
        clusterer.current?.addMarkers(Object.values(markers));
    }, [markers]);

    const setMarkerRef = (marker: Marker | null, key: string) => {
        if (marker && markers[key]) return;
        if (!marker && !markers[key]) return;

        setMarkers(prev => {
            if (marker) {
                return { ...prev, [key]: marker };
            } else {
                const newMarkers = { ...prev };
                delete newMarkers[key];
                return newMarkers;
            }
        });
    };

    return <>{props.referenceObjects.map(referenceObject => <HeaderMapMarker key={referenceObject.id} referenceObject={referenceObject} cbMarkerRef={(mref) => setMarkerRef(mref, referenceObject.id)} />)}</>
}

export const HeaderMapMarkerCollection = withAITracking(reactPlugin, HeaderMapMarkerCollectionComponent);