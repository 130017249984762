export enum FilterRequestMode {
    FAV="favorite", ALL="all", SEARCH="search"
}
export interface FilterRequest {
    mode: FilterRequestMode;
    fullText?: string;
    constructionFilters?: string[];
    buildingFilters?: string[];
    workFilters?: string[];

}