import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Map } from "@vis.gl/react-google-maps";
import { reactPlugin } from "../AppInsights";
import { ReferenceObject } from "../models/ReferenceObject";
import { HeaderMapMarkerCollection } from "./HeaderMapMarkerCollection";

export interface IHeaderMapProps {
    referenceObjects: ReferenceObject[] | undefined;
}

const HeaderMapComponent: React.FunctionComponent<IHeaderMapProps> = (props: IHeaderMapProps) => {

    return <Map
        style={{ width: '1200px', height: '350px' }}
        defaultCenter={{ lat: 47.22680098971313, lng: 8.68597556920241 }}
        defaultZoom={9}
        gestureHandling={'greedy'}
        disableDefaultUI={false}
        mapId="58d4fed720768a26">
        {props.referenceObjects ? <HeaderMapMarkerCollection referenceObjects={props.referenceObjects} /> : null}
    </Map>
}
export const HeaderMap = withAITracking(reactPlugin, HeaderMapComponent);