import { withAITracking } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { reactPlugin } from '../AppInsights';
import { Outlet } from 'react-router-dom';

export interface IScreenLayoutProps {
    isIframe:boolean;
}

const ScreenLayoutComp: React.FunctionComponent<IScreenLayoutProps> = (props: IScreenLayoutProps) => {
    const marginTop = props.isIframe ? "20px":"160px";
    return <div style={{maxWidth:"1200px", margin:"auto", marginTop:marginTop}}>
       <Outlet />
    </div>
}

export const ScreenLayout = withAITracking(reactPlugin, ScreenLayoutComp);