import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Filter } from "../models/Filter";
import { reactPlugin } from "../AppInsights";
import { Button, Combobox, Field, makeStyles, Option, OptionOnSelectData, SelectionEvents, tokens, useId } from "@fluentui/react-components";
import React from "react";
import { Dismiss12Regular } from "@fluentui/react-icons";

export interface IFilterComboboxProps {
    title: string;
    placeholder: string;
    filterOptions: Filter[] | undefined;
    selectedFilters: string[] | undefined;
    onSelectFilter: (ids: string[]) => void;
    disabled: boolean;
}
const useStyles = makeStyles({
    root: {
        // Stack the label above the field with a gap
        display: "grid",
        gridTemplateRows: "repeat(1fr)",
        justifyItems: "start",
        gap: "2px",
        maxWidth: "400px",
    },
    tagsList: {
        listStyleType: "none",
        marginBottom: tokens.spacingVerticalXXS,
        marginTop: 0,
        paddingLeft: 0,
        display: "flex",
        gridGap: tokens.spacingHorizontalXXS,
    },
});

const FilterComboboxComponent: React.FunctionComponent<IFilterComboboxProps> = (props: IFilterComboboxProps) => {
    const comboId = useId("combo-multi");
    const selectedListId = `${comboId}-selection`;
    const selectedListRef = React.useRef<HTMLUListElement>(null);
    const comboboxInputRef = React.useRef<HTMLInputElement>(null);

    const styles = useStyles();
    const onSelectWorkFilters = React.useCallback((event: SelectionEvents, data: OptionOnSelectData) => {
        var selectedIds = data.selectedOptions;
        props.onSelectFilter(selectedIds);
    }, [props]);

    const selectedFilters = React.useMemo(() => !props.filterOptions || !props.selectedFilters ? [] : props.filterOptions.filter(f => props.selectedFilters?.includes(f.id)), [props.filterOptions, props.selectedFilters]);
    const onTagClick = (option: string, index: number) => {
        // remove selected option
        var selectedIds = props.selectedFilters ? props.selectedFilters.filter(f=>f !== option):[];
        props.onSelectFilter(selectedIds);
        
    
        // focus previous or next option, defaulting to focusing back to the combo input
        const indexToFocus = index === 0 ? 1 : index - 1;
        const optionToFocus = selectedListRef.current?.querySelector(
          `#${comboId}-remove-${indexToFocus}`
        );
        if (optionToFocus) {
          (optionToFocus as HTMLButtonElement).focus();
        } else {
          comboboxInputRef.current?.focus();
        }
      };

    return <Field label={props.title}>
        {selectedFilters.length > 0 ? (
            <ul
                id={selectedListId}
                className={styles.tagsList}
                ref={selectedListRef}
            >
                {/* The "Remove" span is used for naming the buttons without affecting the Combobox name */}
                <span id={`${comboId}-remove`} hidden>
                    Remove
                </span>
                {selectedFilters.map((option, i) => (
                    <li key={option.id}>
                        <Button
                            size="small"
                            shape="circular"
                            appearance="primary"
                            icon={<Dismiss12Regular />}
                            iconPosition="after"
                            onClick={() => onTagClick(option.id, i)}
                            id={`${comboId}-remove-${i}`}
                            aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
                        >
                            {option.name}
                        </Button>
                    </li>
                ))}
            </ul>
        ) : null}
        <Combobox
            ref={comboboxInputRef}
            multiselect={true}
            placeholder={props.placeholder}
            disabled={props.disabled}
            selectedOptions={props.selectedFilters || []}
            onOptionSelect={onSelectWorkFilters}>
            {props.filterOptions?.map(f => <Option key={f.id} value={f.id}>{f.name}</Option>)}
        </Combobox>
    </Field>
}

export const FilterCombobox = withAITracking(reactPlugin, FilterComboboxComponent);