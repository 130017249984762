import { makeStyles } from "@fluentui/react-components";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "../AppInsights";
import { ReferenceObject } from "../models/ReferenceObject";
import { ReferencObjectCard } from "./ReferenceObjectCard";

export interface IReferenceObjectListProps {
    items: ReferenceObject[];
    isIframe:boolean;
}
const useStyles = makeStyles({
    main: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        columnGap: "20px",
        rowGap: "20px",
        maxWidth: "1000px",
        margin: "auto"
    },
});

const ReferenceObjectListComponent: React.FunctionComponent<IReferenceObjectListProps> = (props: IReferenceObjectListProps) => {
    const styles = useStyles();
    const slicedArray = React.useMemo(()=>props.isIframe ? props.items.slice(0,9):props.items,[props.items,props.isIframe])
    return <div className={styles.main}>
        {slicedArray.map((item) => <ReferencObjectCard key={item.id} item={item} isIframe={props.isIframe}/>)}
    </div>
}
export const ReferenceObjectList = withAITracking(reactPlugin, ReferenceObjectListComponent);