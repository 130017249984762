import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ReferenceObject } from "../models/ReferenceObject";
import { reactPlugin } from "../AppInsights";
import { useAdvancedMarkerRef, Map, AdvancedMarker, Pin, InfoWindow } from "@vis.gl/react-google-maps";
import React from "react";


export interface IReferenceObjectMapProps {
    referenceObject: ReferenceObject;
}

const ReferenceObjectMapComponent: React.FunctionComponent<IReferenceObjectMapProps> = (props: IReferenceObjectMapProps) => {
    const [markerRef, marker] = useAdvancedMarkerRef();

    const [infoWindowShown, setInfoWindowShown] = React.useState(false);
    const handleClose = React.useCallback(() => setInfoWindowShown(false), []);
    const handleMarkerClick = React.useCallback(
        () => setInfoWindowShown(isShown => !isShown),
        []
    );
    return <Map
        style={{ width: '1000px', height: '450px' }}
        defaultCenter={{ lat: props.referenceObject.latitude, lng: props.referenceObject.longitude }}
        defaultZoom={18}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
        mapId="58d4fed720768a26">
        <AdvancedMarker position={{ lat: props.referenceObject.latitude, lng: props.referenceObject.longitude }} ref={markerRef} onClick={handleMarkerClick} >
            <Pin background={'#009de0'} glyphColor={'#000'} borderColor={'#000'}></Pin>
            {infoWindowShown && (
            <InfoWindow anchor={marker} onClose={handleClose} headerContent={<strong>{props.referenceObject.objectName}</strong>}>
                <p>{props.referenceObject.addressName}<br />
                    {props.referenceObject.postalCode} {props.referenceObject.city}
                </p>
            </InfoWindow>
        )}
        </AdvancedMarker>
        {infoWindowShown && (
            <InfoWindow anchor={marker} onClose={handleClose} headerContent={<strong>{props.referenceObject.objectName}</strong>}>
                <p>{props.referenceObject.addressName}<br />
                    {props.referenceObject.postalCode} {props.referenceObject.city}
                </p>
            </InfoWindow>
        )}
    </Map>
}

export const ReferenceObjectMap = withAITracking(reactPlugin, ReferenceObjectMapComponent);