import { useState, useEffect } from 'react'

import { debounceTime } from 'rxjs/operators'
import { Subject } from 'rxjs'

export const useDebounce = <T>(time: number, initialValue: T) => {
    const [value, setValue] = useState(initialValue)
    const [values] = useState(() => new Subject<T>())
    useEffect(() => {
        const sub = values.pipe(debounceTime(time)).subscribe(setValue);
        return () => sub.unsubscribe();
    }, [time, values])
    return [value, (v: T) => values.next(v)] as const;
}

