import { FilterRequest, FilterRequestMode } from "../models/FilterRequest";
import { ReferenceObject } from "../models/ReferenceObject";
import { Settings } from "../models/Settings";

export enum RO_PICTURETYPE {
    OVERVIEW,SHEET
}

export class ReferenceObjectService {
    private _cache = [] as ReferenceObject[];
    private _settings: Settings | undefined = undefined;

    constructor(private hostNameEnpoint: string,private cdnUrlPath:string) { }

    async getFavoriteOverview(): Promise<ReferenceObject[]> {
        const result = await fetch(`https://${this.hostNameEnpoint}/api/overview`);
        const found = await result.json() as ReferenceObject[];
        found.forEach(r=>this.assignPictureURL(r));
        this.addToCache(found);
        return found;
    }
    async getAllOverview(): Promise<ReferenceObject[]> {
        const result = await fetch(`https://${this.hostNameEnpoint}/api/overview?all=true`);
        const found = await result.json() as ReferenceObject[];
        found.forEach(r=>this.assignPictureURL(r));
        this.addToCache(found);
        return found;
    }
    async getSearchOverview(filterRequest: FilterRequest): Promise<ReferenceObject[]> {
        const result = await fetch(`https://${this.hostNameEnpoint}/api/search`, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(filterRequest)
        });
        const found = await result.json() as ReferenceObject[];
        found.forEach(r=>this.assignPictureURL(r));
        this.addToCache(found);
        return found;
    }

    private addToCache(found: ReferenceObject[]) {
        const notInCache = found.filter(referenceObject => !this._cache.find(cObject => cObject.id === referenceObject.id));
        this._cache = [...this._cache, ...notInCache];
    }
    async getById(id: string): Promise<ReferenceObject> {
        const inCache = this._cache.find(referenceObject => referenceObject.id === id);
        if (!inCache) {
            const result = await fetch(`https://${this.hostNameEnpoint}/api/reference-objects/${id}`);
            const found = await result.json() as ReferenceObject;
            this.assignPictureURL(found);
            this.addToCache([found]);
            return found;
        }
        return inCache;
    }
    async getSettings(): Promise<Settings> {
        if (!this._settings) {
            const result = await fetch(`https://${this.hostNameEnpoint}/api/settings`);
            this._settings = await result.json() as Settings;
        }
        return this._settings;
    }

    async getReferenceOverviewByFilterRequest(filterRequest: FilterRequest): Promise<ReferenceObject[]> {
        switch (filterRequest.mode) {
            case FilterRequestMode.ALL:
                return await this.getAllOverview();
            case FilterRequestMode.FAV:
                return this.getFavoriteOverview();
            case FilterRequestMode.SEARCH:
                return this.getSearchOverview(filterRequest);
        }
    }
    private assignPictureURL(referenceObject:ReferenceObject):void {
        if (referenceObject.hasMainPicture) {
            referenceObject.overviewPictureUrl = `${this.cdnUrlPath}images/${referenceObject.id}/overview.png`;
            referenceObject.sheetPictureUrl = `${this.cdnUrlPath}images/${referenceObject.id}/sheet.png`;
        } else {
            referenceObject.overviewPictureUrl = "alternate_header.png"
        }

    }
}