import { Card, CardHeader, Field, InputOnChangeData, Radio, RadioGroup, RadioGroupOnChangeData, SearchBox, SearchBoxChangeEvent, Title3 } from "@fluentui/react-components";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "../AppInsights";
import { FilterRequest, FilterRequestMode } from "../models/FilterRequest";
import { Settings } from "../models/Settings";
import { useDebounce } from "../services/debounceHook";
import { FilterCombobox } from "./FilterCombobox";

const compareArray = (a: string[], b: string[]): boolean => {
    if (a === b) return true;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export interface IHeaderFilterProps {
    settings?: Settings;
    searchPayload: FilterRequest;
    updateSearchPayload: (payload: FilterRequest) => void;
    updateSearchText: (text: string) => void;
}

const HeaderFilterComponent: React.FunctionComponent<IHeaderFilterProps> = (props: IHeaderFilterProps) => {
    const [searchValue, setSearchValue] = useDebounce(500, "");
    const changeMode = React.useCallback((ev: any, data: RadioGroupOnChangeData) => {
        const value = data.value as FilterRequestMode;
        if (props.searchPayload.mode !== value) {
            const newPayload = { ...props.searchPayload };
            newPayload.mode = value;
            props.updateSearchPayload(newPayload);
        }
    }, [props]);

    const disableSearch = React.useMemo(() => props.searchPayload.mode !== FilterRequestMode.SEARCH, [props.searchPayload]);
    const changeFullText = React.useCallback((event: SearchBoxChangeEvent, data: InputOnChangeData) => {
        console.log("CALL:" + data.value);
        setSearchValue(data.value);
    }, [setSearchValue]);

    React.useEffect(() => {
        if (props.searchPayload.fullText !== searchValue) {
            const payload = { ...props.searchPayload };
            payload.fullText = searchValue;
            props.updateSearchPayload(payload);
        }
    }, [searchValue, props]);
    const onSelectWorkFilters = React.useCallback((selectedIds: string[]) => {
        if (!compareArray(selectedIds, props.searchPayload.workFilters || [])) {
            const payload = { ...props.searchPayload };
            payload.workFilters = selectedIds;
            props.updateSearchPayload(payload);
        }
    }, [props]);
    const onSelectConstructionFilters = React.useCallback((selectedIds: string[]) => {
        if (!compareArray(selectedIds, props.searchPayload.constructionFilters || [])) {
            const payload = { ...props.searchPayload };
            payload.constructionFilters = selectedIds;
            props.updateSearchPayload(payload);
        }
    }, [props]);
    const onSelectBuildingFilters = React.useCallback((selectedIds: string[]) => {
        if (!compareArray(selectedIds, props.searchPayload.buildingFilters || [])) {
            const payload = { ...props.searchPayload };
            payload.buildingFilters = selectedIds;
            props.updateSearchPayload(payload);
        }
    }, [props]);

    return <Card style={{ marginTop: "20px", marginBottom: "20px" }}>
        <CardHeader header={<Title3>Suchen</Title3>}></CardHeader>
        <Field label="Referenzen">
            <RadioGroup value={props.searchPayload.mode} onChange={changeMode} layout="horizontal">
                <Radio value="favorite" label="Aktuelle" />
                <Radio value="all" label="Alle" />
                <Radio value="search" label="Suche" />
            </RadioGroup>
        </Field>
        <div style={{ display: "flex" }}><SearchBox defaultValue={props.searchPayload.fullText} onChange={changeFullText} disabled={disableSearch} /></div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FilterCombobox title="Arbeitsgattung" disabled={disableSearch} placeholder="Arbeitsgattung wählen" filterOptions={props.settings?.workTypeFilters} selectedFilters={props.searchPayload.workFilters} onSelectFilter={onSelectWorkFilters} ></FilterCombobox>
            <FilterCombobox title="Bauart" disabled={disableSearch} placeholder="Bauart wählen" filterOptions={props.settings?.constructionTypeFilters} selectedFilters={props.searchPayload.constructionFilters} onSelectFilter={onSelectConstructionFilters} ></FilterCombobox>
            <FilterCombobox title="Gebäudeart" disabled={disableSearch} placeholder="Gebäudeart wählen" filterOptions={props.settings?.buildingTypeFilters} selectedFilters={props.searchPayload.buildingFilters} onSelectFilter={onSelectBuildingFilters} ></FilterCombobox>
        </div>
    </Card>
}

export const HeaderFilter = withAITracking(reactPlugin, HeaderFilterComponent);