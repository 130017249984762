import { Link, ProgressBar } from "@fluentui/react-components";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "../AppInsights";
import { HeaderFilter } from "../components/HeaderFilter";
import { HeaderMap } from "../components/HeaderMap";
import { ReferenceObjectList } from "../components/ReferenceObjectList";
import { FilterRequest, FilterRequestMode } from "../models/FilterRequest";
import { ReferenceObject } from "../models/ReferenceObject";
import { Settings } from "../models/Settings";
import { ReferenceObjectService } from "../services/ReferenceObjectService";

export interface IOverviewProps {
    service: ReferenceObjectService;
    isIframe: boolean;
}
const initFilterRequestValue = () => {
    const currentPayloadString = sessionStorage.getItem("searchPayLoad");
    return currentPayloadString ? JSON.parse(currentPayloadString) as FilterRequest : { mode: FilterRequestMode.FAV };
}

const OverviewComponent: React.FunctionComponent<IOverviewProps> = (props: IOverviewProps) => {
    const [items, setItems] = React.useState<ReferenceObject[]>();
    const [settings, setSettings] = React.useState<Settings>();
    const [searchPayload, setSearchPayload] = React.useState<FilterRequest>(initFilterRequestValue());
    const updateSearchPayload = React.useCallback((sp: FilterRequest) => {
        sessionStorage.setItem("searchPayLoad", JSON.stringify(sp));
        setSearchPayload(sp);
    }, []);
    React.useEffect(() => {
        props.service.getSettings().then(settings => setSettings(settings));
    }, [props.service]);
    React.useEffect(() => {
        props.service.getReferenceOverviewByFilterRequest(searchPayload).then(res => setItems(res));
    }, [searchPayload, props.service]);
    const updateSearchText = React.useCallback((text: string) => {
        const payload = { ...searchPayload };
        payload.fullText = text;
        setSearchPayload(payload);
    }, [searchPayload]);
    const openURL = React.useMemo(() => {
        return `${window.location.protocol}//${window.location.host}`;
    }, []);

    return <div>
        {!props.isIframe ? <>
            <div><HeaderMap referenceObjects={items}></HeaderMap></div>
            <div style={{ width: "1000px", margin: "auto" }}><HeaderFilter settings={settings} searchPayload={searchPayload} updateSearchPayload={updateSearchPayload} updateSearchText={updateSearchText} /></div>
        </> : null}
        {props.isIframe ? <div style={{ maxWidth: "1020px", display: "flex", justifyContent: "flex-end", margin: 'auto', marginBottom: '10px' }}><Link href={openURL} target="_blank" style={{ marginRight: "10px" }}>Alle Referenzen durchsuchen</Link></div> : null}
        {items && settings ? <ReferenceObjectList items={items} isIframe={props.isIframe} /> : <ProgressBar title="Referenzen laden..." />}
    </div>
}
export const Overview = withAITracking(reactPlugin, OverviewComponent);