import React from "react";
import { useRouteError } from "react-router-dom";

export const ErrorPage: React.FunctionComponent = () => {
    const error = useRouteError() as any;
    console.error(error);

    return (
        <div id="error-page">
            <h1>Urgs!</h1>
            <p>Sorry, ein Fehler ist aufgetreten.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}