import { Button, LargeTitle, makeStyles, SelectTabData, SelectTabEvent, Tab, TabList, TabValue, Text, Title1, Title2, Title3 } from "@fluentui/react-components";
import { ArrowLeft12Regular, Map20Filled, PictureInPicture24Filled } from "@fluentui/react-icons";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { reactPlugin } from "../AppInsights";
import { ReferenceObjectMap } from "../components/ReferenceObjectMap";
import { RoleObjectCard } from "../components/RoleObjectCard";
import { ReferenceObject } from "../models/ReferenceObject";

const useStyles = makeStyles({
    infoColumn: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }
});

const ReferenceDetailComponent: React.FunctionComponent = () => {
    const styles = useStyles();
    const referenceObject = useLoaderData() as ReferenceObject;
    const navigate = useNavigate();
    const backLink = React.useCallback((ev: any) => navigate(`/`), [navigate]);
    const [selectedValue, setSelectedValue] = React.useState<TabValue>(referenceObject.hasMainPicture?"pictures":"map");

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
    };
    const bauArt = React.useMemo(() => {
        const allBauart = referenceObject.attributes.filter(a => a.attributeType === 960450001);
        return allBauart.length > 0 ? allBauart.reduce((acc, current) => acc + ", " + current.name, "").substring(2) : "---"
    }, [referenceObject]);
    const gebauedeArt = React.useMemo(() => {
        const allGebaudeart = referenceObject.attributes.filter(a => a.attributeType === 960450002);
        return allGebaudeart.length > 0 ? allGebaudeart.reduce((acc, current) => acc + ", " + current.name, "").substring(2) : "---"
    }, [referenceObject]);
    const workDone = React.useMemo(() => {
        const allWork = referenceObject.attributes.filter(a => a.attributeType === 960450003);
        return allWork.length > 0 ? allWork.reduce((acc, current) => acc + ", " + current.name, "").substring(2) : "---"
    }, [referenceObject])

    return <div>
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
            <div ><Button size="medium" icon={<ArrowLeft12Regular />} onClick={backLink} /></div>
            <div><LargeTitle>{referenceObject.objectName}</LargeTitle></div>
            <div><Title1>{referenceObject.addressName} {referenceObject.postalCode} {referenceObject.city}</Title1></div>
        </div>
        <hr></hr>
        <div style={{ width: "1200px", display: "flex", flexDirection: "column" }}>
            <TabList selectedValue={selectedValue} onTabSelect={onTabSelect} style={{alignSelf:"center"}}>
                <Tab id="Bilder" icon={<PictureInPicture24Filled />} value="pictures" disabled={!referenceObject.hasMainPicture}>Bilder</Tab>
                <Tab id="Karte" icon={<Map20Filled />} value="map">Karte</Tab>
            </TabList>
            <div style={{ width: "1000px", margin: "auto" }}>
                {selectedValue === "pictures" && <img src={referenceObject.sheetPictureUrl} alt={referenceObject.objectName}></img>}
                {selectedValue === "map" && <ReferenceObjectMap referenceObject={referenceObject}></ReferenceObjectMap>}
            </div>

        </div>
        <div style={{ marginTop: "50px" }}>
            <div><Title2>Projektbeschrieb</Title2></div>
            <div><Text size={400} weight="regular">{referenceObject.description}</Text></div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>
            <div className={styles.infoColumn}>
                <div><Title3>Ausführungsjahr</Title3></div>
                <div><Text weight="regular">{referenceObject.year}</Text></div>
            </div>
            <div className={styles.infoColumn}>
                <div><Title3>Ausgeführte Arbeiten</Title3></div>
                <div><Text weight="regular">{workDone}</Text></div>
            </div>
            <div className={styles.infoColumn}>
                <div><Title3>Bauart</Title3></div>
                <div><Text weight="regular">{bauArt}</Text></div>
            </div>
            <div className={styles.infoColumn}>
                <div><Title3>Gebäudeart</Title3></div>
                <div><Text weight="regular">{gebauedeArt}</Text></div>
            </div>
        </div>
        <div style={{ marginTop: "50px" }}>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>{referenceObject.relations.map(relation => <RoleObjectCard key={relation.roleName + relation.companyName + relation.address} relation={relation} />)}</div>
        </div>
    </div>
}
export const ReferenceDetail = withAITracking(reactPlugin, ReferenceDetailComponent);